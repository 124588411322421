/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function ($) {

    // Use this variable to set up the common and page specific functions. If you
    // rename this variable, you will also need to rename the namespace below.
    var Craft = {
        // All pages
        'common': {
            init: function () {
                
                // Initialize tooltip
                $('[data-toggle="tooltip"]').tooltip();

                // Full screen modal
                $(".modal-fullscreen").on('show.bs.modal', function () {
                    setTimeout( function() {
                        $(".modal-backdrop").addClass("modal-backdrop-fullscreen");
                    }, 0);
                });
                $(".modal-fullscreen").on('hidden.bs.modal', function () {
                    $(".modal-backdrop").addClass("modal-backdrop-fullscreen");
                });

                // Craft adminbar
                var adminBar = $('.admin_bar_wrapper');
                if (adminBar.length) {
                    $('body #content').css({
                        'margin-top': '140px'
                    });
                    $('.navbar-fixed-top').css({
                        'top': '40px'
                    });
                }

                // Video background effect using Vide
                $(window).bind("load", function () {
                    var $video = $('#video');
                    if ($video.length) {
                        $('#video').vide({
                            mp4: $video.data('mp4'),
                            webm: $video.data('webm'),
                            ogv: $video.data('ogv'),
                            poster: $video.data('poster')
                        }, {
                            posterType: 'jpg',
                            loop: true,
                            muted: true,
                            position: '0% 0%'
                        });
                    }
                });

                // Draw SVG objects
                $('.draw').each(function () {
                    // Only animate once, if want to call each time in view, change 'one' to 'bind'
                    $(this).one('inview', function (event, isInView, visiblePartX, visiblePartY) {
                        if (isInView) {
                            // console.log($(this));
                            var objectId = $(this).data("id");
                            $(this).addClass('visible');
                            // Vivus SVG animation
                            new Vivus(objectId, {
                                duration: 100,
                                type: "oneByOne"
                            }, svgCallback);
                        }// End isInView
                    });
                });
                function svgCallback() {}

                // Typewritter effect using Typed.js
                $("#type-it").typed({
                    stringsElement: $('#typed '),
                    typeSpeed: 35,
                    backSpeed: 20,
                    backDelay: 1800,
                    loop: true,
                    loopCount: false
                });

                // Add the velocity animation to animate class
                $('.animate').each(function () {
                    // Only animate once, if want to call each time in view, change 'one' to 'bind'
                    $(this).one('inview', function (event, isInView, visiblePartX, visiblePartY) {
                        if (isInView) {
                            // console.log($(this));
                            var animation = $(this).data("animation");
                            $(this).addClass('visible');
                            $(this).velocity('transition.' + animation, {stagger: 75, duration: 1000});
                        }// End isInView
                    });
                });

                // Portfolio carousel
                $(".carousel").owlCarousel({
                    items: 1,
                    nav: true,
                    autoplay: true,
                    autoplayHoverPause: true,
                    dots: false,
                    lazyLoad: false,
                    loop: true,
                    fluidSpeed: 600,
                    navSpeed: 600,
                    smartSpeed: 600,
                    navText: ["<em class='icon nav-previous'></em>", "<em class='icon nav-next'></em>"]
                });

                // Ajax form submission
                $("form").on({
                    submit: function () {
                        var $self = $(this);
                        $(".alert.alert-success").remove();
                        $("button[type=submit]", $self).prop("disabled", true);

                        $.ajax({
                            url: $("input[name=action]", $(this)).val(),
                            type: "post",
                            dataType: "json",
                            data: $(this).serialize(),
                            success: function (response) {
                                $("ul.errors.help-block", $self).remove();
                                $(".has-error", $self).removeClass("has-error");

                                if (response.success && response.finished) {
                                    $self[0].reset();

                                    var $successMessage = $("<div>", {"class": "alert alert-success"})
                                        .append("<p>Thank you for your inquiry! We will get back to you as quickly as possible.</p>", {"class": "lead"});

                                    $self.before($successMessage);

                                } else if (response.errors) {
                                    for (var key in response.errors) {
                                        if (!response.errors.hasOwnProperty(key)) continue;
                                        var messages = response.errors[key];
                                        var $errors = $("<ul>", {"class": "errors help-block"});
                                        for (var i = 0; i < messages.length; i++) {
                                            var $li = $("<li>");
                                            $li.html(messages[i]);
                                            $errors.append($li);
                                        }

                                        var $input = $("*[name=" + key + "], *[name='" + key + "[]']");

                                        var $formGroup = $input.parents(".form-group");
                                        $formGroup.addClass("has-error");

                                        $formGroup.append($errors);
                                    }
                                }

                                $("button[type=submit]", $self).prop("disabled", false);
                            }
                        });

                        return false;
                    }
                });

                // Progressive nav bar
                // http://codepen.io/lukejacksonn/pen/PwmwWV
                var $nav = $('.greedy-nav');
                var $btn = $('.greedy-nav button');
                var $vlinks = $('.greedy-nav .visible-links');
                var $hlinks = $('.greedy-nav .hidden-links');

                var breaks = [];

                function updateNav() {

                    var availableSpace = $btn.hasClass('hidden') ? $nav.width() : $nav.width() - $btn.width() - 115;
                    var isBrand = false;

                    // The visible list is overflowing the nav
                    if ($vlinks.width() > availableSpace) {

                        // Grab the last child
                        var lastChild = $vlinks.children().last();

                        // Make sure it's not the brand
                        isBrand = (lastChild.hasClass('brand'));

                        if (!isBrand) {
                            // Record the width of the list
                            breaks.push($vlinks.width());
                            // Move item to the hidden list
                            lastChild.prependTo($hlinks);
                        }

                        // Show the dropdown btn
                        if ($btn.hasClass('hidden')) {
                            $btn.removeClass('hidden');
                        }

                        // The visible list is not overflowing
                    } else {

                        // There is space for another item in the nav
                        if (availableSpace > breaks[breaks.length - 1]) {

                            // Move the item to the visible list
                            $hlinks.children().first().appendTo($vlinks);
                            breaks.pop();
                        }

                        // Hide the dropdown btn if hidden list is empty
                        if (breaks.length < 1) {
                            $btn.addClass('hidden');
                            $hlinks.addClass('hidden');
                        }
                    }

                    // Keep counter updated
                    $btn.attr("count", breaks.length);

                    // Recur if the visible list is still overflowing the nav
                    if ($vlinks.width() > availableSpace && !isBrand) {
                        updateNav();
                    }

                }

                // Window listeners
                var progressiveNav = debounce(function () {
                    updateNav();
                }, 20);
                window.addEventListener('resize', progressiveNav);

                $btn.on('click', function () {
                    $hlinks.toggleClass('hidden');
                });

                updateNav();

                // Auto-hide navigation
                var mainHeader = $('.navbar-default'),
                //this applies only if secondary nav is below intro section
                    belowNavHeroContent = $('.sub-nav-hero'),
                    headerHeight = mainHeader.height();

                //set scrolling variables
                var scrolling = false,
                    previousTop = 0,
                    currentTop = 0,
                    scrollDelta = 10,
                    scrollOffset = 100;

                mainHeader.on('click', '.nav-trigger', function (event) {
                    // open primary navigation on mobile
                    event.preventDefault();
                    mainHeader.toggleClass('nav-open');
                });

                var menuScrolling = debounce(function () {
                    if (!scrolling) {
                        scrolling = true;
                        if (!window.requestAnimationFrame) {
                            setTimeout(autoHideHeader, 250);
                        } else {
                            requestAnimationFrame(autoHideHeader);
                        }
                    }
                }, 10);
                var menuResizing = debounce(function () {
                    headerHeight = mainHeader.height();
                }, 10);

                window.addEventListener('scroll', menuScrolling);
                window.addEventListener('resize', menuResizing);

                function autoHideHeader() {
                    var currentTop = $(window).scrollTop();

                    if ( belowNavHeroContent.length > 0 ) {
                        checkStickyNavigation(currentTop);
                    } else {
                        checkSimpleNavigation(currentTop);
                    }

                    previousTop = currentTop;
                    scrolling = false;
                }

                function checkSimpleNavigation(currentTop) {
                    //there's no secondary nav or secondary nav is below primary nav
                    if (previousTop - currentTop > scrollDelta) {
                        //if scrolling up...
                        mainHeader.removeClass('is-hidden');
                    } else if (currentTop - previousTop > scrollDelta && currentTop > scrollOffset) {
                        //if scrolling down...
                        mainHeader.addClass('is-hidden');
                    }
                }

                function checkStickyNavigation(currentTop) {
                    //secondary nav below intro section - sticky secondary nav
                    var secondaryNavOffsetTop = belowNavHeroContent.offset().top - mainHeader.height();

                    if (previousTop >= currentTop) {
                        //if scrolling up...
                        if (currentTop < secondaryNavOffsetTop) {
                            //secondary nav is not fixed
                            mainHeader.removeClass('is-hidden');
                            belowNavHeroContent.removeClass('secondary-nav-fixed');
                        } else if (previousTop - currentTop > scrollDelta) {
                            //secondary nav is fixed
                            mainHeader.removeClass('is-hidden');
                            belowNavHeroContent.addClass('secondary-nav-fixed');
                        }

                    } else {
                        //if scrolling down...
                        if (currentTop > secondaryNavOffsetTop + scrollOffset) {
                            //hide primary nav
                            mainHeader.addClass('is-hidden');
                            belowNavHeroContent.addClass('secondary-nav-fixed');
                        } else if (currentTop > secondaryNavOffsetTop) {
                            //once the secondary nav is fixed, do not hide primary nav if you haven't scrolled more than scrollOffset
                            mainHeader.removeClass('is-hidden');
                            belowNavHeroContent.addClass('secondary-nav-fixed');
                        }

                    }
                }

                // Responsive video embed (via FitVid.js)
                $('[data-video="responsive"]').fitVids();

                // Returns a function, that, as long as it continues to be invoked, will not
                // be triggered. The function will be called after it stops being called for
                // N milliseconds. If `immediate` is passed, trigger the function on the
                // leading edge, instead of the trailing.
                function debounce(func, wait, immediate) {
                    var timeout;
                    return function () {
                        var context = this, args = arguments;
                        var later = function () {
                            timeout = null;
                            if (!immediate) func.apply(context, args);
                        };
                        var callNow = immediate && !timeout;
                        clearTimeout(timeout);
                        timeout = setTimeout(later, wait);
                        if (callNow) func.apply(context, args);
                    };
                }

                // jQuery smoothState
                // Info: https://github.com/miguel-perez/smoothState.js
                var smoothState = $('#container').smoothState({
                    prefetch: true,
                    cacheLength: 2,
                    pageCacheSize: 4,
                    scroll: false,
                    debug: false,
                    blacklist: '.no-smoothState, *[name="form_page_submit"], .admin_bar_wrapper a, *[data-lightbox="gallery"], .at-share-btn, .dropdown-toggle, *[data-toggle="modal"]',
                    onStart: {
                        duration: 250, // Duration of our animation
                        render: function ($container) {
                            // Add your CSS animation reversing class
                            $container.addClass('slide-out');
                            $container.removeClass('slide-in');
                            // Restart your animation
                            smoothState.restartCSSAnimations();
                            // Scroll user to the top
                            $('body').animate({'scrollTop': 0});
                        }
                    },
                    onReady: {
                        duration: 0,
                        render: function ($container, $newContent) {

                            // Inject the new content
                            $container.html($newContent);
                        }
                    },
                    onProgress: {
                        // How long this animation takes
                        duration: 500,
                        // A function that dictates the animations that take place
                        render: function ($container) {
                            $container.find('.loader').css('display', 'block');
                        }
                    },
                    onAfter: function ($container, $newContent) {

                        // Remove your CSS animation reversing class
                        $container.addClass('slide-in');
                        $container.removeClass('slide-out');

                        // Re-add the docReady functions
                        $.readyFn.execute();

                        // Scroll to top of doc
                        var $hash = $(window.location.hash);
                        if ($hash.length !== 0) {
                            var offsetTop = $hash.offset().top;
                            $('body, html').animate({
                                scrollTop: ( offsetTop - 60 )
                            }, {
                                duration: 280
                            });
                        }
                    }
                }).data('smoothState');
            },
            finalize: function () {
                // JavaScript to be fired on all pages, after page specific JS is fired
            }
        },
        // Home page
        'home': {
            init: function () {
                // JavaScript to be fired on the home page
            },
            finalize: function () {
                // JavaScript to be fired on the home page, after the init JS
            }
        },
        // About us page, note the change from about-us to about_us.
        'about_us': {
            init: function () {
                // JavaScript to be fired on the about us page
            },
            finalize: function () {
                // JavaScript to be fired on the home page, after the init JS
            }
        }
    };

    // The routing fires all common scripts, followed by the page specific scripts.
    // Add additional events for more control over timing e.g. a finalize event
    var UTIL = {
        fire: function (func, funcname, args) {
            var fire;
            var namespace = Craft;
            funcname = (funcname === undefined) ? 'init' : funcname;
            fire = func !== '';
            fire = fire && namespace[func];
            fire = fire && typeof namespace[func][funcname] === 'function';

            if (fire) {
                namespace[func][funcname](args);
            }
        },
        loadEvents: function () {
            // Fire common init JS
            UTIL.fire('common');

            // Fire page-specific init JS, and then finalize JS
            $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function (i, classnm) {
                UTIL.fire(classnm);
                UTIL.fire(classnm, 'finalize');
            });

            // Fire common finalize JS
            UTIL.fire('common', 'finalize');
        }
    };

    // Load Events
    $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.


/**
 *  Helper functions
 */

function loadJS(script){
    if (!script) {return false;}
    var scr = document.createElement('script');
    scr.type = 'text/javascript'; scr.async = true;
    scr.src = script;
    var s = document.getElementsByTagName('script')[0];
    s.parentNode.insertBefore(scr, s);
}